import React from 'react'

const Form5 = ({formData, setFormData}) => {
    

    return (
        <div className='space-y-4'>
            <input type='text' placeholder='Username' value={formData.username} 
            onChange={(event) => setFormData({...formData, username: event.target.value})}
            className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
            />
            <input type='text' placeholder='Password' value={formData.password} 
            onChange={(event) => setFormData({...formData, password: event.target.value})}
            className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
            />
        </div>
    )
}

export default Form5