import { getBackendUrl } from "./getBackendUrl";


const verfiyToken = () => {
    return fetch(`${getBackendUrl('verify-token')}`, {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    })
    .then(response => {
        if (response.ok){
            return response.json();
        }
        throw new Error('Token is invalid or expired');
    });
}

export default verfiyToken;
