import React, { useState, useEffect } from "react";
import ResponsivePie from "./PieChart.js";
import BarChart from "./BarChart.js";
import StackedLineChart from "./StackedLineChart.js";

import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import FinanceService from "../../Services/FInanceService.js";
import AddExpense from "./AddExpenses.js";
import { MdDelete } from "react-icons/md";
const Finance = () => {
  const [revenueData, setRevenueData] = useState([]);
  const [expenseData, setExpenseData] = useState([]);
  const [revenue, setrevenue] = useState([]);
  const [expense, setexpense] = useState([]);

  const fetchData = async () => {
    const { data: revenue } = await FinanceService.listFinance("revenue");
    const { data: expense } = await FinanceService.listFinance("expense");
    revenue?.finances?.forEach((item) => {
      setRevenueData((prev) => [
        ...prev,
        { value: item.amount, name: item.name },
      ]);
    });
    expense?.finances?.forEach((item) => {
      setExpenseData((prev) => [
        ...prev,
        { value: item.amount, name: item.name },
      ]);
    });
    setrevenue(revenue.finances);
    setexpense(expense.finances);
  };
  const handleDeleteClick = (id) => {
    confirmAlert({
      title: "Confirm Deletion",
      message: "Are you sure you want to delete this record?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            try {
              await FinanceService.deletedFinance(id);

              fetchData();
            } catch (error) {
              console.error(error);
            }
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <div className="sm:w-[85%] m-auto my-8 ">
      <div className="w-full flex justify-end items-center my-5"></div>
      <div className="flex  justify-around items-center my-10 flex-wrap">
        <div className="flex flex-col justify-center items-center">
          <h1 className="text-3xl text-center">Current Income</h1>

          <ResponsivePie data={revenueData} />
        </div>
        <div className="flex flex-col justify-center items-center">
          <h1 className="text-3xl text-center">Current Expenditures</h1>

          <ResponsivePie data={expenseData} />
        </div>
      </div>
      <AddExpense fetchdata={fetchData} />
      <div>
        <div className="flex flex-col">
          <h1 className="text-center text-3xl my-10 w-full bg-green-200 rounded-sm p-2 shadow-xl">
            Revenue
          </h1>
          <div className="flex flex-col sm:shadow-shadow-neo my-5">
            <BarChart data={revenue} type={"Revenues"} />
          </div>

          <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
              <div className="overflow-hidden">
                <table className="min-w-full text-left text-sm font-light shadow-inner rounded-lg p-2">
                  <thead className="border-b font-medium dark:border-neutral-500">
                    <tr>
                      <th scope="col" className="px-6 py-4">
                        Name
                      </th>

                      <th scope="col" className="px-6 py-4">
                        Amount Earned
                      </th>
                      <th scope="col" className="px-6 py-4">
                        Date
                      </th>
                      <th scope="col" className="px-6 py-4">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {revenue?.length > 0 ? (
                      revenue?.map?.((equipment) => (
                        <tr
                          key={equipment._id}
                          className="border-b dark:border-neutral-500"
                        >
                          <td className="whitespace-nowrap px-6 py-4">
                            {equipment.name}
                          </td>

                          <td className="whitespace-nowrap px-6 py-4">
                            {equipment.amount}
                          </td>
                          <td className="whitespace-nowrap px-6 py-4">
                            {equipment.date.split("T")[0]}
                          </td>
                          <td className="whitespace-nowrap px-6 py-4">
                            <MdDelete
                              cursor={"pointer"}
                              onClick={() => handleDeleteClick(equipment._id)}
                            />
                          </td>
                        </tr>
                      ))
                    ) : (
                      <h1 className="text-xl w-full !text-center my-2">
                        No Revenue data added yet
                      </h1>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col ">
          <h1 className="text-center text-3xl my-10 w-full bg-red-200 rounded-sm p-2 shadow-xl">
            Expenses
          </h1>
          <div className="flex flex-col sm:shadow-shadow-neo my-5">
            <BarChart data={expense} />
          </div>
          <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
              <div className="overflow-hidden">
                <table className="min-w-full text-left text-sm font-light  shadow-inner rounded-lg p-2">
                  <thead className="border-b font-medium dark:border-neutral-500">
                    <tr>
                      <th scope="col" className="px-6 py-4">
                        Name
                      </th>

                      <th scope="col" className="px-6 py-4">
                        Amount Earned
                      </th>
                      <th scope="col" className="px-6 py-4">
                        Date
                      </th>
                      <th scope="col" className="px-6 py-4">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {expense?.length > 0 ? (
                      expense?.map?.((equipment) => (
                        <tr
                          key={equipment._id}
                          className="border-b dark:border-neutral-500"
                        >
                          <td className="whitespace-nowrap px-6 py-4">
                            {equipment.name}
                          </td>

                          <td className="whitespace-nowrap px-6 py-4">
                            {equipment.amount}
                          </td>
                          <td className="whitespace-nowrap px-6 py-4">
                            {equipment.date.split("T")[0]}
                          </td>
                          <td className="whitespace-nowrap px-6 py-4">
                            <MdDelete
                              className="cursor-pointer"
                              onClick={() => handleDeleteClick(equipment._id)}
                            />
                          </td>
                        </tr>
                      ))
                    ) : (
                      <h1 className="text-xl my-2 text-center w-full">
                        No Expense data added yet
                      </h1>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Finance;
