import React, { useRef, useEffect } from "react";
import * as echarts from "echarts/core";
import { TooltipComponent, LegendComponent } from "echarts/components";
import { PieChart } from "echarts/charts";
import { LabelLayout } from "echarts/features";
import { CanvasRenderer } from "echarts/renderers";

const EChart = ({ data }) => {
  echarts.use([
    TooltipComponent,
    LegendComponent,
    PieChart,
    CanvasRenderer,
    LabelLayout,
  ]);
  const chartRef = useRef();

  useEffect(() => {
    const option = {
      tooltip: {
        show: true,
        position: "inside",
      },
      //   legend: {
      //     top: "5%",
      //     left: "center",
      //     margin: "1rem",
      //     textStyle: {
      //       color: "grey", // set the color of the label text
      //     },
      //   },
      series: [
        {
          seriesLayoutBy: "column",
          name: "Name",
          type: "pie",
          radius: ["40%", "70%"],
          avoidLabelOverlap: true,
          itemStyle: {
            borderRadius: 5,
            borderColor: "#fff",
            borderWidth: 2,
          },
          label: {
            show: false,
            position: "center",
            color: "grey",
          },
          emphasis: {
            label: {
              show: true,
              fontSize: 10,
              fontWeight: "bold",
              margin: "0.5rem",
            },
          },
          labelLine: {
            show: false,
          },
          data: data,
        },
      ],
    };
    const chart = echarts?.init(chartRef?.current);

    if (option) {
      chart.setOption(option);
    }
    window.addEventListener("resize", () => {
      chart.resize();
    });

    return () => {
      // Cleanup the event listener when the component unmounts
      window.addEventListener("resize", () => {
        chart.resize();
      });

      chart.dispose();
    };
  }, [data]);
  const chartStyle = {
    width: "100%",
    height: "400px",
    margin: "auto",
  };

  return <div style={chartStyle} ref={chartRef} />;
};

export default EChart;
