import React, { useRef, useEffect } from "react";
import * as echarts from "echarts/core";
import {
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
} from "echarts/components";
import { LineChart } from "echarts/charts";
import { UniversalTransition } from "echarts/features";
import { CanvasRenderer } from "echarts/renderers";

const LineChartCustom = ({ data }) => {
  const trend = [];
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  data?.forEach?.((item) => {
    const month = new Date(item._id).getMonth();

    trend[month] = (trend[month] || 0) + item.totalAmount;
  });
  const option = {
    tooltip: {
      trigger: "axis",
    },
    legend: {
      data: ["Trend"],
      textStyle: {
        color: "grey",
      },
    },
    grid: {
      left: "3%",
      right: "4%",
      bottom: "3%",
      containLabel: true,
    },
    toolbox: {
      feature: {
        saveAsImage: {},
      },
    },
    xAxis: {
      type: "category",
      boundaryGap: false,
      data: months,
    },
    yAxis: {
      type: "value",
    },
    series: [
      {
        name: "Trends",
        type: "line",
        stack: "Total",
        data: trend,
        smooth: true,
      },
    ],
  };
  echarts.use([
    TitleComponent,
    ToolboxComponent,
    TooltipComponent,
    GridComponent,
    LegendComponent,
    LineChart,
    CanvasRenderer,
    UniversalTransition,
  ]);
  const chartRef = useRef();

  useEffect(() => {
    const chart = echarts?.init(chartRef?.current);

    if (option) {
      chart.setOption(option);
    }
    window.addEventListener("resize", () => {
      chart.resize();
    });

    return () => {
      // Cleanup the event listener when the component unmounts
      window.addEventListener("resize", () => {
        chart.resize();
      });

      chart.dispose();
    };
  }, [data]);
  const chartStyle = {
    width: "100%",
    height: "400px",
    margin: "auto",
  };

  return <div style={chartStyle} ref={chartRef} />;
};

export default LineChartCustom;
