import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import Login from "./components/Login";
import Dashboard from "./components/Dashboard";
import CropDetails from "./components/CropDetails";
import Home from "./components/Home";
import Register from "./components/Register";
import ConfirmationPage from "./components/register_components/Confirmation";
import AllCrops from "./components/AllCrops";
import verifyToken from './components/utils/verifyToken';

// import "./tailwind.css";

function RedirectToHTML() {
  let navigate = useNavigate();
  React.useEffect(() => {
    window.location.href = process.env.PUBLIC_URL + "/landing.html";
  }, [navigate]);

  return null;
}

function App() {

  React.useEffect(() => {
    const interval = setInterval(() => {
      verifyToken().then(data => {
        console.log('Token verficiation resopnse', data);
      })
      .catch(error => {
        console.error(error);
      });
    }, 30*60*1000);

    return () => clearInterval(interval);
  }, [])

  return (
    <Router>
      <Routes>
        <Route path="/home" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/dashboard/*" element={<Dashboard />} />
        <Route path="/register" element={<Register />} />
        <Route path="/confirmation" element={<ConfirmationPage />} />
        <Route path="/" element={<RedirectToHTML />} />{" "}
        {/* Default route redirects to m.html */}
      </Routes>
    </Router>
  );
}

export default App;
