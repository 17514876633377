import React, { useEffect, useState } from "react";
import WeatherService from "../../Services/WeatherAlert";
import { IoIosSettings } from "react-icons/io";
import "./weather.css";
import "./neomorphic.css"; // Add a new CSS file for neomorphic styles
import { Input, Modal, Switch, message } from "antd";
import UserService from "../../Services/UserService";
import { FaSpinner } from "react-icons/fa";

const WeatherComponent = () => {
  const [open, setOpen] = useState(false);
  const [user, setUser] = useState({});
  const [weatherData, setWeatherData] = useState(null);
  const convertKelvinToCelsius = (kelvin) => kelvin - 273.15;

  const getData = async () => {
    const [{ data }, { data: user }] = await Promise.all([
      WeatherService.getWeatherData(),
      UserService.me(),
    ]);
    setUser({ ...user?.user });
    setWeatherData({ ...data.data });
  };

  useEffect(() => {
    getData();
  }, []);

  if (!weatherData) {
    return <FaSpinner className="animate-spin mx-auto my-10 text-xl" />;
  }

  const { hourly, daily } = weatherData;

  const renderHourlyForecast = () => {
    return hourly?.map((hour, index) => (
      <div className="relative">
        <div className="cardContainer">
          <div className="card">
            <p className="city"> {index === 0 ? "Now" : `+${index}h`}</p>
            <p className="weather capitalize">
              {hour?.weather[0]?.description}
            </p>
            <svg
              className="weather"
              version="1.1"
              id="Layer_1"
              x="0px"
              y="0px"
              width="50px"
              height="50px"
              viewBox="0 0 100 100"
              xmlSpace="preserve"
            >
              <image
                id="image0"
                width={100}
                height={100}
                x={0}
                y={0}
                href={`http://openweathermap.org/img/wn/${hour?.weather[0]?.icon}.png`}
              />
            </svg>
            <p className="temp">
              {`${Math.round(convertKelvinToCelsius(hour?.temp))}°`}
            </p>
            <div className="minmaxContainer">
              <div className="min">
                <p className="minHeading">Cloudiness</p>
                <p className="minTemp">{hour?.clouds}%</p>
              </div>
              <div className="max">
                <p className="maxHeading">Humidity</p>
                <p className="maxTemp">{hour?.humidity}%</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    ));
  };

  const renderDailyForecast = () => {
    return daily?.slice(0, 3).map((day, index) => (
      <div
        key={index}
        className="neomorphic-card rounded-lg row align-items-center"
      >
        <div className="col-lg-6">
          <strong>
            {index === 0
              ? "Today"
              : index === 1
              ? "Tomorrow"
              : "Day after tomorrow"}
          </strong>
        </div>
        <div className="col-lg-2 text-center">
          <img
            src={`http://openweathermap.org/img/wn/${day?.weather[0]?.icon}.png`}
            className="w-100"
            alt=""
          />
        </div>
        <div className="col-lg-4 text-end">
          <span>{`${Math.round(
            convertKelvinToCelsius(day?.temp?.day)
          )}°`}</span>
        </div>
      </div>
    ));
  };
  const getBackgroundImage = () => {
    switch (weatherData?.current?.weather[0].main) {
      case "Snow":
        return "https://mdbgo.io/ascensus/mdb-advanced/img/snow.gif";
      case "Clouds":
        return "https://mdbgo.io/ascensus/mdb-advanced/img/clouds.gif";
      case "Fog":
        return "https://mdbgo.io/ascensus/mdb-advanced/img/fog.gif";
      case "Rain":
        return "https://mdbgo.io/ascensus/mdb-advanced/img/rain.gif";
      case "Clear":
        return "https://mdbgo.io/ascensus/mdb-advanced/img/clear.gif";
      case "Thunderstorm":
        return "https://mdbgo.io/ascensus/mdb-advanced/img/thunderstorm.gif";
      default:
        return "https://mdbgo.io/ascensus/mdb-advanced/img/clear.gif";
    }
  };
  return (
    <section className="vh-100 flex w-full overflow-hidden">
      <Modal
        open={open}
        closable
        onCancel={() => setOpen(false)}
        onOk={() => {
          try {
            setOpen(false);
            const payload = { ...user };
            UserService.update(payload).then(() => {
              message.success("Successfully Updated");
              getData();
            });
          } catch (error) {
            message.error("Something went wrong.");
            console.log(error);
          }
        }}
        prefixCls="antdmodalweather"
      >
        <div className="flex flex-col gap-3">
          <div>
            <label htmlFor="lat">Latitute</label>
            <Input
              inputMode="decimal"
              type="number"
              value={user?.latitude}
              onChange={(e) =>
                setUser((pree) => ({ ...pree, latitude: e.target.value }))
              }
              id="lat"
              placeholder="Please enter valid values"
            />
          </div>
          <div>
            <label htmlFor="lon">Longitude</label>
            <Input
              inputMode="decimal"
              type="number"
              value={user?.longitude}
              onChange={(e) =>
                setUser((pree) => ({ ...pree, longitude: e.target.value }))
              }
              id="lon"
              placeholder="Please enter valid values"
            />
          </div>
          <div>
            <label htmlFor="alert">Do you want weather alerts?</label>
            <Switch
              id="alert"
              className="bg-gray-500"
              checked={user?.alert}
              onChange={(e) => setUser((pree) => ({ ...pree, alert: e }))}
            />
          </div>
          {user?.alert && (
            <div className="flex flex-wrap gap-4">
              <div>
                <label>Temperature Threshold</label>
                <div className="flex flex-wrap justify-between items-center">
                  <label htmlFor="mint">Min</label>
                  <Input
                    value={user?.thresholdTemperature}
                    onChange={(e) =>
                      setUser((pree) => ({
                        ...pree,
                        temperatureMin: e.target.value,
                      }))
                    }
                    id="mint"
                    placeholder="Please enter valid values"
                  />
                  <label htmlFor="maxt">Max</label>
                  <Input
                    value={user?.thresholdTemperature}
                    onChange={(e) =>
                      setUser((pree) => ({
                        ...pree,
                        temperatureMax: e.target.value,
                      }))
                    }
                    id="maxt"
                    placeholder="Please enter valid values"
                  />
                </div>
              </div>

              <div>
                <label>Rainfall Threshold</label>
                <div className="flex flex-wrap justify-between items-center">
                  <label htmlFor="minr">Min</label>
                  <Input
                    id="minr"
                    value={user?.thresholdTemperature}
                    onChange={(e) =>
                      setUser((pree) => ({
                        ...pree,
                        rainFallMin: e.target.value,
                      }))
                    }
                    placeholder="Please enter valid values"
                  />
                  <label htmlFor="maxr">Max</label>
                  <Input
                    id="maxr"
                    value={user?.thresholdTemperature}
                    onChange={(e) =>
                      setUser((pree) => ({
                        ...pree,
                        rainFallMax: e.target.value,
                      }))
                    }
                    placeholder="Please enter valid values"
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </Modal>
      <div className="w-full overflow-hidden weather-content">
        <div className="w-full flex justify-content-center align-items-center h-100">
          <div className=" overflow-hidden">
            <div
              id="wrapper-bg"
              className="neomorphic-card bg-image shadow-4-strong h-screen"
              style={{ backgroundImage: `url('${getBackgroundImage()}')` }}
            >
              {/* Main current data */}
              <div className="neomorphic-card-header p-4 border-0">
                <IoIosSettings onClick={() => setOpen(true)} />
                <div className="text-center mb-3">
                  <p className="text-6xl text-white font-bold mb-1">
                    {Math.round(
                      convertKelvinToCelsius(weatherData?.current?.temp)
                    )}
                    °
                  </p>
                  <p className="mb-1" id="wrapper-description"></p>
                  <p className="display-1 mb-1" id="wrapper-temp"></p>
                  <span className="">
                    Pressure: {weatherData?.current?.pressure}
                  </span>
                  <span className="mx-2">|</span>
                  <span className="">
                    Humidity: {weatherData?.current?.humidity}
                  </span>
                </div>
              </div>

              {/* Hourly forecast */}
              <div className="neomorphic-card-body p-4 border-top border-bottom mb-2 ">
                <div className="flex gap-3 overflow-auto py-2 text-center hourly overflow-y-hidden">
                  {renderHourlyForecast()}
                </div>
              </div>

              {/* Daily forecast */}
              <div className="neomorphic-card-body p-5 ">
                {renderDailyForecast()}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WeatherComponent;
