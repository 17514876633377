import axios from "axios";
import { getBackendUrl } from "../utils/getBackendUrl";
class WeatherAlert {
  api;
  constructor(baseURL) {
    this.api = axios.create({
      baseURL,
      headers: { Authorization: `bearer ${localStorage.getItem("token")}` },
    });
  }
  getWeatherData() {
    return this.api.get("/");
  }
}
const WeatherService = new WeatherAlert(getBackendUrl("weather"));
export default WeatherService;
