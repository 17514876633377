import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from 'axios';
import './styles/Home.css'
import { Link } from 'react-router-dom';
import cultura_logo from '../images/cultura_logo.png'

const Home = () => {


    return (
        <div className="home-wrapper">
            <div className=" navbar">
                <img src={cultura_logo} className="cultura_image_home" alt='Cultura Logo'/>
                <Link to={`/login`}><button className="home-top-login">Login</button></Link>
            </div>
            <div className=" info-wrapper-home">
                <h1 className="home-title">Welcome to Cultura</h1>
                <h2 className="home-sub">The all-in-one app for your farm.</h2>
                <div className="home-buttons">
                    <Link to={`/register`}><button className="home-register">Register</button></Link>
                    <Link to={`/login`}><button className="home-login">Login</button></Link>
                </div>
            </div>
        </div>
    )
}

export default Home;