import React, { useState, useEffect } from "react";
import ResponsivePie from "./PieChart.js";
import {
  GiAppleSeeds,
  GiFertilizerBag,
  GiVendingMachine,
} from "react-icons/gi";
import { GiPlantRoots } from "react-icons/gi";
import { MdOutlinePestControl } from "react-icons/md";
import { FaWater } from "react-icons/fa";
import StackedLineChart from "../Finances/StackedLineChart.js";
import FinanceService from "../../Services/FInanceService.js";
import LineChart from "./LineChart.js";
const defaultCategories = {
  Fertilizer: GiFertilizerBag,
  Herbicide: GiPlantRoots,
  Pesticide: MdOutlinePestControl,
  Irrigation: FaWater,
  Machinery: GiVendingMachine,
  Seeds: GiAppleSeeds,
};
const Analytics = () => {
  const [productTrends, setProductTrends] = useState([]);
  const [monthlyTrends, setMonthlyTrends] = useState([]);
  const [totalIncome, setTotalIncome] = useState(0);
  const [totalExpense, setTotalExpense] = useState(0);
  const [expenseCategories, setExpenseCategories] = useState([]);
  const [recentExpenses, setRecentExpenses] = useState([]);
  const [revenue, setrevenue] = useState([]);
  const [expense, setexpense] = useState([]);

  const fetchData = async () => {
    const { data: revenue } = await FinanceService.listFinance("revenue");
    const { data: expense } = await FinanceService.listFinance("expense");

    setrevenue(revenue.finances);
    setexpense(expense.finances);
  };
  useEffect(() => {
    fetchMonthlyTrends();

    fetchExpenseCategories();
    fetchRecentExpenses();
    fetchData();
  }, []);

  const fetchMonthlyTrends = async () => {
    try {
      const { data } = await FinanceService.getMonthlyTrends();
      setMonthlyTrends(data);
    } catch (error) {
      console.error("Error fetching monthly trends:", error);
    }
  };

  const fetchExpenseCategories = async () => {
    try {
      const { data } = await FinanceService.getExpenseCategories();

      setExpenseCategories(
        data.map((item) => ({ value: item.totalAmount, name: item._id }))
      );
      FinanceService?.getTrends()
        .then((data) => {
          setProductTrends(data.data.result);
        })
        .catch((e) => {
          console.log(e);
        });
    } catch (error) {
      console.error("Error fetching expense categories:", error);
    }
  };

  const fetchRecentExpenses = async () => {
    try {
      const { data } = await FinanceService.getAnalyticsData();
      setRecentExpenses(data?.lastWeekResults);
      setTotalExpense(data?.totalExpense);
      setTotalIncome(data?.totalRevenue);
    } catch (error) {
      console.error("Error fetching recent expenses:", error);
    }
  };

  return (
    <div className="sm:w-[85%] m-auto my-8 ">
      <div className="flex justify-around items-center my-10">
        <div>
          <h1 className="text-3xl text-center">Total Income: ${totalIncome}</h1>
        </div>
        <div>
          <h1 className="text-3xl text-center">
            Total Expense: ${totalExpense}
          </h1>
        </div>
      </div>
      <div className="w-full flex flex-row-reverse">
        <div className="flex flex-col w-full justify-center items-center">
          <h1 className="text-3xl text-center">Monthly Trends</h1>
          <div className="flex flex-col sm:shadow-shadow-neo w-full">
            {monthlyTrends?.length > 0 && <LineChart data={monthlyTrends} />}
          </div>
        </div>

        <div className="flex flex-col w-full justify-center items-center mt-8">
          <h1 className="text-3xl text-center">Expense Categories</h1>
          <div className="flex flex-col w-full sm:shadow-shadow-neo">
            {expenseCategories?.length > 0 && (
              <ResponsivePie data={expenseCategories} />
            )}
          </div>
        </div>
      </div>

      <div className="mt-8">
        <h1 className="text-3xl text-center mb-4">Recent Expenses</h1>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
          {recentExpenses?.map?.((expense) => (
            <div
              key={expense._id}
              className="bg-white p-4 rounded-md shadow-md flex flex-col justify-between"
            >
              <h2 className="text-lg font-semibold mb-2">{expense.name}</h2>
              <p className="text-gray-600 mb-2">${expense.amount}</p>
              <p className="text-gray-500">
                {new Date(expense.date).toLocaleDateString()}
              </p>
            </div>
          ))}
        </div>
      </div>
      <h1 className="text-center text-3xl my-10">Comparison</h1>

      <div className="flex flex-col sm:shadow-shadow-neo">
        <StackedLineChart data={revenue?.concat?.(expense)} />
      </div>
      <h1 className="text-center text-3xl my-10">Product Trends</h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
        {Object.keys(defaultCategories)?.map?.((expense) => {
          const Element = defaultCategories[expense];
          return (
            <div className="bg-white p-4 rounded-md shadow-md flex justify-between items-center">
              <div key={expense} className=" flex flex-col justify-between">
                <h2 className="text-lg font-semibold mb-2">{expense}</h2>
                <p className="text-gray-600 mb-2">
                  {productTrends?.find((e) => e.expenditureType === expense)
                    ?.totalAmount
                    ? `$${
                        productTrends?.find(
                          (e) => e.expenditureType === expense
                        )?.totalAmount
                      }`
                    : "No data yet!"}
                </p>
                <p className="text-gray-500">
                  Product Name:{" "}
                  {productTrends?.find((e) => e.expenditureType === expense)
                    ?.mostCommonName
                    ? productTrends?.find((e) => e.expenditureType === expense)
                        ?.mostCommonName
                    : "N/A"}
                </p>
                <p className="text-gray-500">
                  Total Entries:{" "}
                  {productTrends?.find((e) => e.expenditureType === expense)
                    ?.count
                    ? productTrends?.find((e) => e.expenditureType === expense)
                        ?.count
                    : 0}
                </p>
              </div>
              <Element fontSize={80} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Analytics;
