import axios from "axios";
import { getBackendUrl } from "../utils/getBackendUrl";
class User {
  api;
  constructor(baseURL) {
    this.api = axios.create({
      baseURL,
      headers: { Authorization: `bearer ${localStorage.getItem("token")}` },
    });
  }
  update(data) {
    return this.api.put("/", data);
  }
  me() {
    return this.api.get("/me");
  }
}
const UserService = new User(getBackendUrl("user"));
export default UserService;
