import React from 'react'

const Form5 = ({formData, setFormData}) => {
    

    return (
        <div className='space-y-4'>
            <input type='text' placeholder='Farm Type' value={formData.farmType} 
            onChange={(event) => setFormData({...formData, farmType: event.target.value})}
                            className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
            />
            <input type='text' placeholder='Farm Acreage' value={formData.farmAcreage} 
            onChange={(event) => setFormData({...formData, farmAcreage: event.target.value})}
                            className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
            />
            <input type='text' placeholder='Crops Grown' value={formData.cropsGrown} 
            onChange={(event) => setFormData({...formData, cropsGrown: event.target.value})}
                            className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
            />
        </div>
    )
}

export default Form5