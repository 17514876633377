import { useState } from "react";
import { Button, Input, Modal, Select, message } from "antd";
import { MdAdd } from "react-icons/md";

import FinanceService from "../../Services/FInanceService";
const defaultOptions = [
  "Fertilizer",
  "Herbicide",
  "Pesticide",
  "Irrigation",
  "Machinery",
  "Seeds",
];
function AddExpense({ refresh, fetchdata }) {
  const [isOpen, setIsOpen] = useState(false);
  const [name, setName] = useState("");
  const [type, setType] = useState("expense");
  const [amount, setAmount] = useState("");
  const [date, setDate] = useState(new Date().toISOString().slice(0, 10));
  const [expenditureType, setExpenditureType] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (name.trim() === "") {
      return message.info("Please enter a valid name");
    }

    if (amount.trim() === "" || !/^[0-9]+$/.test(amount)) {
      return message.info("Please enter a valid amount number");
    }

    const finance = {
      name,
      type,
      amount: Number(amount),
      date: new Date(date),
      expenditureType,
    };

    console.log(finance);
    try {
      const response = await FinanceService.createFinance(finance);
      if (!response.error) {
        message.success("Entry added");

        if (fetchdata) fetchdata();
      }
    } catch (error) {
      message.error("Something went wrong");
      console.log(error);
    }
    setName("");
    setType("expense");
    setAmount("");
    setDate(new Date().toISOString().slice(0, 10));

    setIsOpen(false);
  };

  return (
    <>
      <Button
        type="primary"
        className="p-2 rounded-md !bg-primary shadow-shadow-neo flex items-center"
        onClick={() => setIsOpen(true)}
      >
        New Entry
        <MdAdd />
      </Button>
      <Modal
        open={isOpen}
        closable
        prefixCls="antdmodalweather"
        okText="Create"
        onCancel={() => setIsOpen(false)}
        onOk={handleSubmit}
        title="New Entry"
      >
        <div>
          <div className="mt-4">
            <label>Name (Specified Brand Name) </label>
            <Input
              type="text"
              value={name}
              onChange={(event) => setName(event.target.value)}
            />
          </div>
          <div className="mt-4 flex gap-3 items-center ">
            <label>Type</label>
            <Select
              className="border-none shadow-none"
              value={type}
              onChange={(value) => setType(value)}
              options={[
                { value: "expense", label: "Expense" },
                { value: "revenue", label: "Revenue" },
              ]}
            />
          </div>
          {type === "expense" && (
            <div className="my-4 flex flex-col">
              <label htmlFor="">Expense Type</label>
              <Select
                placeholder="Expense Type"
                options={defaultOptions.map((e) => ({ value: e, label: e }))}
                value={expenditureType}
                onChange={(e) => {
                  setExpenditureType(e);
                }}
              />
            </div>
          )}
          <div className="mt-4">
            <label>Amount</label>
            <Input
              type="text"
              value={amount}
              onChange={(event) => setAmount(event.target.value)}
            />
          </div>
          <div className="mt-4">
            <label>Date</label>
            <Input
              type="date"
              value={date}
              onChange={(event) => setDate(event.target.value)}
            />
          </div>
        </div>
      </Modal>
    </>
  );
}

export default AddExpense;
