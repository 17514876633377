import axios from "axios";
import { getBackendUrl } from "../utils/getBackendUrl";
class Finance {
  api;
  constructor(baseURL) {
    this.api = axios.create({
      baseURL,
      headers: { Authorization: `bearer ${localStorage.getItem("token")}` },
    });
  }
  createFinance(data) {
    return this.api.post("/", data);
  }
  deletedFinance(id) {
    return this.api.delete("/" + id);
  }
  detailFinance(id) {
    return this.api.get("/" + id);
  }
  getMonthlyTrends() {
    return this.api.get("/getMonthlyTrends");
  }
  getExpenseCategories() {
    return this.api.get("/getExpenseCategories");
  }
  getTrends() {
    return this.api.get("/getTrends");
  }
  listFinance(type) {
    return this.api.get("/?type=" + type);
  }
  getAnalyticsData() {
    return this.api.get("/getAnalyticsData");
  }
  updateFinance(id, data) {
    return this.api.put("/" + id, data);
  }
}
const FinanceService = new Finance(getBackendUrl("finances"));
export default FinanceService;
