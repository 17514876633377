import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import "./styles/CropDetails.css";
import { Link } from "react-router-dom";
import { FaTrash, FaArrowLeft, FaSpinner } from "react-icons/fa";
import { getBackendUrl } from "./utils/getBackendUrl";

const CropDetails = () => {
  const { cropId } = useParams();
  const [crop, setCrop] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchCrop() {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          `${getBackendUrl("crops")}/${cropId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setCrop(response.data);
      } catch (error) {
        console.error("Error fetching crop details:");
      }
    }
    fetchCrop();
  }, [cropId]);

  async function handleDeleteCrop() {
    const confirmDelete = window.confirm("Are you sure you want to delete?");
    if (confirmDelete) {
      try {
        const token = localStorage.getItem("token");
        await axios.delete(`${getBackendUrl("crops")}/${cropId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        alert("Crop deleted successfully!");
        navigate(-1); // Redirect back
      } catch (error) {
        console.error("Error deleting crop:", error);
        alert("Error deleting crop. Please try again, ");
      }
    }
  }
  function getNextAndFutureStages(growthStagesData) {
    const today = new Date();
    return (
      growthStagesData.filter((data) => new Date(data.date) >= today) || []
    );
  }
  function getCurrentStage(growthStagesData) {
    const today = new Date();
    const pastStages = growthStagesData.filter(
      (data) => new Date(data.date) <= today
    );
    return pastStages[pastStages.length - 1] || null;
  }

  function formatDate(dateString) {
    const date = new Date(dateString);
    const options = { year: "numeric", month: "long", day: "numeric" };
    const formattedDate = date.toLocaleDateString("en-US", options);

    let day = date.getDate();
    let suffix = "th";

    if (day === 1 || day === 21 || day === 31) {
      suffix = "st";
    } else if (day === 2 || day === 22) {
      suffix = "nd";
    } else if (day === 3 || day === 23) {
      suffix = "rd";
    }

    const monthName = formattedDate.split(" ")[0];
    const year = date.getFullYear();

    return `${monthName} ${day}${suffix}, ${year}`;
  }

  function goHome() {}

  if (!crop) {
    return <FaSpinner className="spinner" />;
  }

  return (
    <div className="bg-gray-100 min-h-screen p-8 flex justify-center">
      <div className="bg-white p-6 rounded-lg shadow-md w-3/4">
        <div className="flex justify-between items-center mb-1">
          <div className="flex items-center">
            <button
              onClick={() => navigate(-1)}
              className="mr-4 p-2 rounded-full hover:bg-emerald-200 transition duration-300"
            >
              <FaArrowLeft />
            </button>
            <h1 className="text-2xl font-bold text-emerald-700 capitalize">
              {crop.cropType}
            </h1>
          </div>
          <div className="flex justify-between items-center hover:text-red-500">
            <button
              onClick={handleDeleteCrop}
              className="right p-2 rounded-full hover:bg-red-200 transition duration-300 hover:text-red-500"
            >
              <FaTrash className="text-gray-500 hover:text-red-500" />
            </button>
          </div>
        </div>
        <p className="mb-2">
          <span className="font-semibold">Address:</span> {crop.address}
        </p>
        <p className="mb-4">
          <span className="font-semibold">Planted Date:</span>{" "}
          {formatDate(crop.plantedDate)}
        </p>

        <h3 className="text-xl font-semibold mb-2 text-emerald-700">
          Current Growth Stage
        </h3>
        {getCurrentStage(crop.growthStagesData) ? (
          <div className="p-4 mb-4 border-l-4 border-emerald-500">
            <h3 className="text-lg font-bold mb-1">
              {getCurrentStage(crop.growthStagesData).stage}
            </h3>
            <p className="mb-2 text-gray-600">
              {getCurrentStage(crop.growthStagesData).date
                ? formatDate(getCurrentStage(crop.growthStagesData).date)
                : "Invalid Date"}
            </p>
            <p className="text-gray-500">
              {getCurrentStage(crop.growthStagesData).recommendation ||
                "No Recommendations Created"}
            </p>
          </div>
        ) : (
          <p className="mb-4">Initial stage, no growth stages recorded yet.</p>
        )}

        <h3 className="text-xl font-semibold mb-2 text-emerald-700">
          Future Growth Stages
        </h3>
        <ul>
          {getNextAndFutureStages(crop.growthStagesData).map((data, index) => (
            <li className="p-4 mb-4 border-l-4 border-emerald-500" key={index}>
              <h3 className="text-lg font-bold mb-1">{data.stage}</h3>
              <p className="mb-2 text-gray-600">
                {data.date ? formatDate(data.date) : "Invalid Date"}
              </p>
              <p className="text-gray-500">{data.recommendation}</p>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default CropDetails;
