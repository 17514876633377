import React from 'react'

const Form3 = ({formData, setFormData}) => {
    

    return (
        <div className='space-y-4'>
            <input type='text' placeholder='Country' value={formData.country} 
            onChange={(event) => setFormData({...formData, country: event.target.value})}
            className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
            />
            <input type='text' placeholder='City' value={formData.city} 
            onChange={(event) => setFormData({...formData, city: event.target.value})}
            className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
            />
        </div>
    )
}

export default Form3