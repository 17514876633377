import React, { useRef, useEffect } from "react";
import * as echarts from "echarts/core";
import { GridComponent } from "echarts/components";
import { BarChart } from "echarts/charts";
import { CanvasRenderer } from "echarts/renderers";

const EChart = ({ data, type }) => {
  const expenses = [];

  const days = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

  data?.forEach?.((item) => {
    const day = days[new Date(item.date).getDay()];

    expenses[days.indexOf(day)] =
      (expenses[days.indexOf(day)] || 0) + item.amount;
  });

  const option = {
    tooltip: {
      show: true,
      position: "inside",
    },
    legend: {
      top: "5%",
      left: "center",
      margin: "1rem",
      textStyle: {
        color: "grey",
      },
    },
    xAxis: {
      type: "category",
      data: days,
    },
    yAxis: {
      type: "value",
    },
    series: [
      {
        name: type || "Expenses",
        data: expenses,
        type: "bar",
      },
    ],
  };
  echarts.use([GridComponent, BarChart, CanvasRenderer]);
  const chartRef = useRef();

  useEffect(() => {
    const chart = echarts?.init(chartRef?.current);

    if (option) {
      chart.setOption(option);
    }
    window.addEventListener("resize", () => {
      chart.resize();
    });

    return () => {
      // Cleanup the event listener when the component unmounts
      window.addEventListener("resize", () => {
        chart.resize();
      });

      chart.dispose();
    };
  }, [data]);
  const chartStyle = {
    width: "100%",
    height: "400px",
    margin: "auto",
  };

  return <div style={chartStyle} ref={chartRef} />;
};

export default EChart;
