import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import cultura_logo from "../images/cultura_logo.png";
import { Link } from "react-router-dom";
import Form1 from "../components/register_components/Form1";
import Form2 from "../components/register_components/Form2";
import Form3 from "../components/register_components/Form3";
import Form4 from "../components/register_components/Form4";
import Form5 from "../components/register_components/Form5";
import { getBackendUrl } from "./utils/getBackendUrl";

const Register = () => {
  const [page, setPage] = useState(0);
  const [formData, setFormData] = useState({
    username: "",
    password: "",
    name: "",
    email: "",
    farm: "",
    phone: "",
    country: "",
    city: "",
    farmType: "",
    farmAcerage: "",
    cropsGrown: "",
  });

  const [isSubmitting, setIsSubmitting] = useState(false); // Add a state to track submission
  const FormTitles = [
    "A Few Details...",
    "Getting Closer...",
    "Just a few more",
    "Almost there...",
    "One more...",
  ];
  const navigate = useNavigate();

  const handleSubmit = async () => {
    if (isSubmitting) {
      return; // Prevent multiple submissions
    }

    setIsSubmitting(true);

    try {
      const response = await axios.post(
        `${getBackendUrl("auth")}/register`,
        formData
      );
      if (response.status === 201) {
        alert("Successfully added to the waitlist");
        setIsSubmitting(false); // Reset the submission state
        navigate("/confirmation"); // Redirects to the confirmation page
      }
    } catch (error) {
      console.error("There was an error submitting the form", error);
      setIsSubmitting(false); // Reset the submission state
    }
  };

  const PageDisplay = () => {
    switch (page) {
      case 0:
        return <Form1 formData={formData} setFormData={setFormData} />;
      case 1:
        return <Form2 formData={formData} setFormData={setFormData} />;
      case 2:
        return <Form3 formData={formData} setFormData={setFormData} />;
      case 3:
        return <Form4 formData={formData} setFormData={setFormData} />;
      case 4:
        return <Form5 formData={formData} setFormData={setFormData} />;
      default:
        return null;
    }
  };

  return (
    <div className="min-h-screen flex register-container flex-col justify-center bg-gray-100">
      <div className="mx-auto w-full max-w-md p-6 bg-white rounded shadow-md space-y-6">
        <div className="flex justify-center">
          <Link to={`/home`}>
            <img
              src={cultura_logo}
              className="cultura_image_home h-12"
              alt="Cultura Logo"
            />
          </Link>
        </div>
        <div className="text-center">
          <h1 className="text-2xl font-bold text-gray-700">
            {FormTitles[page]}
          </h1>
        </div>
        <div>{PageDisplay()}</div>
        <div className="flex justify-between">
          <button
            disabled={page === 0}
            onClick={() => setPage((currPage) => currPage - 1)}
            className="py-2 px-4 bg-gray-500 text-white rounded hover:bg-gray-400 transition duration-300 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            Previous
          </button>
          <button
            onClick={() => {
              if (page === FormTitles.length - 1) {
                handleSubmit();
              } else {
                setPage((currPage) => currPage + 1);
              }
            }}
            disabled={isSubmitting}
            className="py-2 px-4 bg-emerald-600 text-white rounded hover:bg-emerald-700 transition duration-300 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            {isSubmitting
              ? "Submitting..."
              : page === FormTitles.length - 1
              ? "Submit"
              : "Next"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Register;
