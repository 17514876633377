import React from 'react';

const Form1 = ({ formData, setFormData }) => {
    return (
        <div className="space-y-4">
            <input 
                type="text" 
                placeholder="Name" 
                value={formData.name}
                onChange={(event) => setFormData({ ...formData, name: event.target.value })}
                className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
                required
            />
            <input 
                type="text" 
                placeholder="Email" 
                value={formData.email}
                onChange={(event) => setFormData({ ...formData, email: event.target.value })}
                className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
                required
            />
            <input 
                type="number" 
                placeholder="Phone Number" 
                value={formData.phone}
                onChange={(event) => setFormData({ ...formData, phone: event.target.value })}
                className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
            />
        </div>
    );
}

export default Form1;
