// ConfirmationPage.js
import React from "react";
import { Link } from "react-router-dom";

const ConfirmationPage = () => {
  return (
    <div>
      <h2>Thank you for sgining up!</h2>
      <p>You are now able to login and begin your journey with cultura!.</p>
      <Link to="/">Back to Home</Link>
    </div>
  );
};

export default ConfirmationPage;
