import React from 'react'

const Form2 = ({formData, setFormData}) => {
    

    return (
        <div className='space-y-4'>
            <input type='text' placeholder='Farm Name' value={formData.farm} 
            onChange={(event) => setFormData({...formData, farm: event.target.value})}
            className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
            />
        </div>
    )
}

export default Form2