import React, { useState, useEffect } from "react";
import axios from "axios"; // Assuming you're using axios for HTTP requests.
import { Link, Route, Routes } from "react-router-dom";
import "./styles/Dashboard.css";
import {
  FaChartBar,
  FaSpinner,
  FaDollarSign,
  FaHome,
  FaCloud,
  FaSeedling,
} from "react-icons/fa";
import Weather from "./Pages/Weather/Weather";
import Finance from "./Pages/Finances/Finances";
import { getBackendUrl } from "./utils/getBackendUrl";
import { IoIosSettings } from "react-icons/io";
import Analytics from "./Pages/Analytics/Analytics";
import { Menu } from "antd";
import AllCrops from "./AllCrops";
import CropDetails from "./CropDetails";
import FinanceService from "./Services/FInanceService";
import moment from "moment";
import { FcRatings } from "react-icons/fc";
import ResponsivePie from "./Pages/Finances/PieChart";
import WeatherService from "./Services/WeatherAlert";
import UserService from "./Services/UserService";
const Dashboard = () => {
  const [open, setOpen] = useState(false);
  const [isCornSelected, setIsCornSelected] = useState(false);
  const [recentExpenses, setRecentExpenses] = useState([]);
  const [isSoySelected, setIsSoySelected] = useState(false);
  const [error, setError] = useState(null);
  const [crops, setCrops] = useState([]);
  const [cropData, setCropData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [revenueData, setRevenueData] = useState([]);
  const [expenseData, setExpenseData] = useState([]);
  const [weatherData, setWeatherData] = useState();
  const [newCrop, setNewCrop] = useState({
    cropType: "",
    address: "",
    plantedDate: "",
  });
  const convertKelvinToCelsius = (kelvin) => kelvin - 273.15;
  const displayedCrops = crops.slice(0, 3);
  const fetchRecentExpenses = async () => {
    try {
      const { data } = await FinanceService.getAnalyticsData();
      setRecentExpenses(data?.lastWeekResults?.slice(0, 5));
    } catch (error) {
      console.error("Error fetching recent expenses:", error);
    }
  };
  const getData = async () => {
    const { data } = await WeatherService.getWeatherData();

    setWeatherData({ ...data.data });
  };

  useEffect(() => {
    getData();
  }, []);
  const fetchData = async () => {
    const { data: revenue } = await FinanceService.listFinance("revenue");
    const { data: expense } = await FinanceService.listFinance("expense");
    revenue?.finances?.forEach((item) => {
      setRevenueData((prev) => [
        ...prev,
        { value: item.amount, name: item.name },
      ]);
    });
    expense?.finances?.forEach((item) => {
      setExpenseData((prev) => [
        ...prev,
        { value: item.amount, name: item.name },
      ]);
    });
  };
  useEffect(() => {
    // This function fetches the user's crops when the component mounts
    async function fetchCrops() {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(`${getBackendUrl("crops")}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setCrops(response.data);
        fetchRecentExpenses();
        fetchData();
        setLoading(false);
      } catch (error) {
        console.error("Error fetching crops:", error);
        setError("Failed to fetch crops.");
        setLoading(false);
      }
    }
    fetchCrops();
    fetch(`${getBackendUrl("crops")}/data`)
      .then((response) => response.json())
      .then((data) => {
        console.log(data); // <-- Add this line
        setCropData(data);
      })
      .catch((error) => console.error("Error loading crop data:", error));
  }, []);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setNewCrop((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    if (name === "cropType") {
      setIsCornSelected(value === "corn");
    }
    if (name === "cropType") {
      setIsSoySelected(value === "soybean");
    }
  };

  const handleAddCrop = async (event) => {
    event.preventDefault();

    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${getBackendUrl("crops")}/add`,
        newCrop,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Append the new crop to the current state
      setCrops((prevCrops) => [...prevCrops, response.data]);
      setNewCrop({
        cropType: "",
        address: "",
        plantedDate: "",
      });
    } catch (err) {
      if (err.response && err.response.data) {
        setError(err.response.data);
      } else {
        setError("An error occurred while adding the crop. Please try again.");
      }
    }
  };
  function formatDate(dateString) {
    const date = new Date(dateString);
    const options = { year: "numeric", month: "long", day: "numeric" };
    const formattedDate = date.toLocaleDateString("en-US", options);

    let day = date.getDate();
    let suffix = "th";

    if (day === 1 || day === 21 || day === 31) {
      suffix = "st";
    } else if (day === 2 || day === 22) {
      suffix = "nd";
    } else if (day === 3 || day === 23) {
      suffix = "rd";
    }

    const monthName = formattedDate.split(" ")[0];
    const year = date.getFullYear();

    return `${monthName} ${day}${suffix}, ${year}`;
  }
  function getNextGrowthStage(growthStagesData) {
    // Get today's date
    const today = new Date();

    // Sort the growthStagesData by date
    const sortedStages = growthStagesData.sort(
      (a, b) => new Date(a.date) - new Date(b.date)
    );

    // Find the next growth stage
    for (let stage of sortedStages) {
      if (new Date(stage.date) >= today) {
        return stage;
      }
    }

    // If no future date is found, return the last one
    return sortedStages[sortedStages.length - 1];
  }

  if (loading) {
    return <FaSpinner className="animate-spin mx-auto my-10 text-xl" />;
  }

  return (
    <div className="h-screen flex bg-gray-100">
      {/* Sidebar */}
      <div className="w-1/5 min-h-screen bg-[#001529]  ">
        <h1 className="text-2xl font-bold text-gray-800 text-center">
          <img
            src="https://uploads-ssl.webflow.com/64f258e5ec85b8aa528d4a71/64f25b4884d2e29d3f3a436e_realculturaname.png"
            alt=""
            className="[filter:drop-shadow(2px_4px_6px_black)]"
            style={{
              shapeImageThreshold: "0.1px",
            }}
          />
        </h1>

        <Menu mode="vertical" prefixCls="mainmenu" theme="dark">
          <Menu.Item key="dashboard" icon={<FaHome />}>
            <Link to="/dashboard">Dashboard</Link>
          </Menu.Item>
          <Menu.Item key="crops" icon={<FaSeedling />}>
            <Link to="/dashboard/crops">Your Crops</Link>
          </Menu.Item>
          <Menu.Item key="weather" icon={<FaCloud />}>
            <Link to="/dashboard/weather">Weather Alerts</Link>
          </Menu.Item>
          <Menu.Item key="finances" icon={<FaDollarSign />}>
            <Link to="/dashboard/finances">Financial Data</Link>
          </Menu.Item>
          <Menu.Item key="analytics" icon={<FaChartBar />}>
            <Link to="/dashboard/analytics">Community Trends</Link>
          </Menu.Item>
        </Menu>
      </div>

      {/* Main Content */}
      <div className="w-full overflow-x-hidden overflow-y-auto">
        <div className="">
          {/* Your Crops Section */}

          <Routes>
            <Route
              path="/"
              element={
                <div className="flex-1 p-8 overflow-y-auto">
                  <div className="mb-3">
                    <div className="flex justify-between items-center mb-3">
                      <h1 className="text-2xl font-bold">Your Crops</h1>
                      {displayedCrops.length < crops.length && (
                        <Link to="/crops/" className="text-gray-500">
                          {"View More >> "}
                        </Link>
                      )}
                    </div>
                    {displayedCrops.length === 0 ? (
                      <p className="text-gray-600">
                        You haven't added any crops yet.
                      </p>
                    ) : (
                      <div className="grid grid-cols-3 gap-6">
                        {displayedCrops.map((crop) => {
                          const nextStage = getNextGrowthStage(
                            crop.growthStagesData
                          );
                          return (
                            <div
                              key={crop._id}
                              className="bg-white p-6 rounded-lg shadow-md"
                            >
                              <h2 className="text-xl font-bold mb-4 capitalize">
                                {crop.cropType}
                              </h2>
                              <div className="text-gray-700 mb-2">
                                <strong>Next Stage:</strong>{" "}
                                {nextStage?.stage || "N/A"}
                              </div>
                              <div className="text-gray-600 mb-4">
                                {formatDate(nextStage?.date || new Date())}
                              </div>
                              <Link
                                to={`/dashboard/cropsde/${crop._id}`}
                                className="text-green-600 hover:underline"
                              >
                                Details
                              </Link>
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>
                  <div className="mt-10 p-6 bg-gray-100 rounded">
                    <h1 className="text-xl font-bold mb-6">Weather Alerts</h1>
                    <div className="neomorphic-card-header p-4 border-0">
                      <div className="text-center mb-3">
                        <p className="text-6xl text-white font-bold mb-1">
                          {Math.round(
                            convertKelvinToCelsius(weatherData?.current?.temp)
                          )}
                          °
                        </p>
                        <p className="mb-1" id="wrapper-description"></p>
                        <p className="display-1 mb-1" id="wrapper-temp"></p>
                        <span className="">
                          Pressure: {weatherData?.current?.pressure}
                        </span>
                        <span className="mx-2">|</span>
                        <span className="">
                          Humidity: {weatherData?.current?.humidity}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="mt-10 p-6 bg-gray-100 rounded">
                    <h1 className="text-xl font-bold mb-6">Financial Data</h1>
                    <div className="flex  justify-around items-center my-10 flex-wrap">
                      <div className="flex flex-col justify-center items-center">
                        <h1 className="text-3xl text-center">Current Income</h1>

                        <ResponsivePie data={revenueData} />
                      </div>
                      <div className="flex flex-col justify-center items-center">
                        <h1 className="text-3xl text-center">
                          Current Expenditures
                        </h1>

                        <ResponsivePie data={expenseData} />
                      </div>
                    </div>
                  </div>

                  <div className="mt-10 p-6 bg-gray-100 rounded">
                    <h1 className="text-xl font-bold mb-6">Analytics</h1>
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                      {recentExpenses?.map?.((expense) => (
                        <div className="flex bg-white p-4 rounded-md shadow-md justify-between items-center">
                          <div
                            key={expense._id}
                            className=" flex flex-col justify-between"
                          >
                            <h2 className="text-lg font-semibold mb-2">
                              {expense.name}
                            </h2>
                            <p className="text-gray-600 mb-2">
                              ${expense.amount}
                            </p>
                            <p className="text-gray-500">
                              {moment(expense?.date).fromNow()}
                            </p>
                          </div>
                          <FcRatings fontSize={88} />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              }
            />
            <Route path="/weather" element={<Weather />} />
            <Route path="/finances" element={<Finance />} />
            <Route path="/analytics" element={<Analytics />} />
            <Route path="/crops" element={<AllCrops />} />
            <Route path="/cropsde/:cropId" element={<CropDetails />} />
          </Routes>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
/**Weather Alerts Section
 */
