import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import "./index.css";
import { ConfigProvider } from "antd";

const root = document.getElementById("root");
const appRoot = createRoot(root);

appRoot.render(
  <ConfigProvider>
    <App />
  </ConfigProvider>
);
