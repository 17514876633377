import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import {
  FaChartBar,
  FaSpinner,
  FaDollarSign,
  FaHome,
  FaCloud,
  FaSeedling,
} from "react-icons/fa";
import { getBackendUrl } from "./utils/getBackendUrl";

const AllCrops = () => {
  const [isCornSelected, setIsCornSelected] = useState(false);
  const [isSoySelected, setIsSoySelected] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [error, setError] = useState(null);
  const [crops, setCrops] = useState([]);
  const [cropData, setCropData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [newCrop, setNewCrop] = useState({
    cropType: "",
    address: "",
    plantedDate: "",
  });

  useEffect(() => {
    // This function fetches the user's crops when the component mounts
    async function fetchCrops() {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(getBackendUrl("crops"), {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setCrops(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching crops:", error);
        setError("Failed to fetch crops.");
        setLoading(false);
      }
    }

    fetchCrops();
    fetch(getBackendUrl("crops") + "/data")
      .then((response) => response.json())
      .then((data) => {
        console.log(data); // <-- Add this line
        setCropData(data);
      })
      .catch((error) => console.error("Error loading crop data:", error));
  }, []);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setNewCrop((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    if (name === "cropType") {
      setIsCornSelected(value === "corn");
    }
    if (name === "cropType") {
      setIsSoySelected(value === "soybean");
    }
  };
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };
  const handleSelectChange = (name, value) => {
    const event = {
      target: {
        name: name,
        value: value,
      },
    };
    handleInputChange(event);
  };

  const handleAddCrop = async (event) => {
    event.preventDefault();

    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${getBackendUrl("crops")}/add`,
        newCrop,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Append the new crop to the current state
      setCrops((prevCrops) => [...prevCrops, response.data]);
      setNewCrop({
        cropType: "",
        address: "",
        plantedDate: "",
      });
    } catch (err) {
      if (err.response && err.response.data) {
        setError(err.response.data);
      } else {
        setError("An error occurred while adding the crop. Please try again.");
      }
    }
  };
  function formatDate(dateString) {
    const date = new Date(dateString);
    const options = { year: "numeric", month: "long", day: "numeric" };
    const formattedDate = date.toLocaleDateString("en-US", options);

    let day = date.getDate();
    let suffix = "th";

    if (day === 1 || day === 21 || day === 31) {
      suffix = "st";
    } else if (day === 2 || day === 22) {
      suffix = "nd";
    } else if (day === 3 || day === 23) {
      suffix = "rd";
    }

    const monthName = formattedDate.split(" ")[0];
    const year = date.getFullYear();

    return `${monthName} ${day}${suffix}, ${year}`;
  }
  function getNextGrowthStage(growthStagesData) {
    // Get today's date
    const today = new Date();

    // Sort the growthStagesData by date
    const sortedStages = growthStagesData.sort(
      (a, b) => new Date(a.date) - new Date(b.date)
    );

    // Find the next growth stage
    for (let stage of sortedStages) {
      if (new Date(stage.date) >= today) {
        return stage;
      }
    }

    // If no future date is found, return the last one
    return sortedStages[sortedStages.length - 1];
  }

  if (loading) {
    return <FaSpinner className="text-4xl animate-spin mx-auto mt-48" />;
  }
  return (
    <div className="h-screen flex bg-gray-100">
      {/* Main Content */}
      <div className="flex-1 p-8 overflow-y-auto">
        <div className="mb-8">
          <div className="flex justify-between items-center">
            <h1 className="text-3xl font-semibold mb-1 text-emerald-700">
              All Crops
            </h1>
            <button
              className="bg-green-500 py-3 px-6 font-sans text-xs font-bold uppercase text-white shadow-md shadow-green-500/20 transition-all hover:shadow-lg hover:shadow-green-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none text-white p-2 rounded hover:shadow-lg transition duration-300"
              onClick={toggleModal}
            >
              Add Crop
            </button>
          </div>
          {isModalOpen && (
            <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center z-50">
              <div
                className="absolute top-0 left-0 w-full h-full bg-black opacity-50"
                onClick={toggleModal}
              ></div>

              <form
                onSubmit={handleAddCrop}
                className="space-y-8 z-10 relative w-3/4 md:w-1/2 lg:w-1/3 bg-white p-6 rounded-lg shadow-md"
              >
                {/* <Icon name="close" onClick={toggleModal} className="absolute top-2 right-2 text-gray-500 cursor-pointer" /> */}

                <h1 className="text-2xl font-semibold mb-1 text-green-600">
                  Add Crop
                </h1>
                <div class="relative h-10 w-72 min-w-[200px]">
                  <select
                    name="cropType"
                    value={newCrop.cropType}
                    onChange={handleInputChange}
                    label="Choose Crop"
                    class="peer h-full w-full rounded-[7px] border border-blue-gray-200 border-t-transparent bg-transparent px-3 py-2.5 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 empty:!bg-red-500 focus:border-2 focus:border-green-500 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
                  >
                    {Object.keys(cropData).map((crop) => (
                      <option key={crop} value={crop}>
                        {crop}
                      </option>
                    ))}
                  </select>

                  <label class="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-green-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:border-green-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:border-green-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                    Select Crop
                  </label>
                </div>

                {/* Conditional inputs based on crop type */}
                {isCornSelected && (
                  <div class="w-72">
                    <div class="relative h-10 w-full min-w-[200px]">
                      <input
                        type="number"
                        name="relativeMaturity"
                        value={newCrop.relativeMaturity || ""}
                        onChange={handleInputChange}
                        class="peer h-full w-full rounded-[7px] border border-blue-gray-200 border-t-transparent bg-transparent px-3 py-2.5 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-green-500 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
                      />
                      <label class="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-green-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:border-green-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:border-green-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                        Relative Maturity (days)
                      </label>
                    </div>
                  </div>
                )}
                {isSoySelected && (
                  <div class="w-72">
                    <div class="relative h-10 w-full min-w-[200px]">
                      <input
                        type="number"
                        name="daysToMaturity"
                        value={newCrop.daysToMaturity}
                        onChange={handleInputChange}
                        class="peer h-full w-full rounded-[7px] border border-blue-gray-200 border-t-transparent bg-transparent px-3 py-2.5 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-green-500 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
                      />
                      <label class="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-green-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:border-green-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:border-green-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                        Days to Maturity
                      </label>
                    </div>
                  </div>
                )}

                <div class="w-72">
                  <div class="relative h-10 w-full min-w-[200px]">
                    <input
                      type="text"
                      name="address"
                      value={newCrop.address}
                      onChange={handleInputChange}
                      class="peer h-full w-full rounded-[7px] border border-blue-gray-200 border-t-transparent bg-transparent px-3 py-2.5 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-green-500 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
                    />
                    <label class="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-green-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:border-green-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:border-green-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                      Field Address
                    </label>
                  </div>
                </div>
                <div class="w-72">
                  <div class="relative h-10 w-full min-w-[200px]">
                    <input
                      type="date"
                      name="plantedDate"
                      value={newCrop.plantedDate}
                      onChange={handleInputChange}
                      class="peer h-full w-full rounded-[7px] border border-blue-gray-200 border-t-transparent bg-transparent px-3 py-2.5 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-green-500 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
                    />
                    <label class="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-green-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:border-green-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:border-green-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                      Planting Date
                    </label>
                  </div>
                </div>

                <button
                  class="middle none center w-full rounded-lg bg-green-500 py-3 px-6 font-sans text-xs font-bold uppercase text-white shadow-md shadow-green-500/20 transition-all hover:shadow-lg hover:shadow-green-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                  data-ripple-light="true"
                >
                  Add Crop
                </button>
              </form>
            </div>
          )}

          <div className="grid grid-cols-3 gap-6">
            {crops.length === 0 ? (
              <p className="text-gray-600">You haven't added any crops yet.</p>
            ) : (
              crops.map((crop) => {
                const nextStage = getNextGrowthStage(crop.growthStagesData);
                return (
                  <div
                    key={crop._id}
                    className="bg-white p-6 rounded-lg shadow-md"
                  >
                    <h2 className="text-xl font-bold mb-4 capitalize">
                      {crop.cropType}
                    </h2>
                    <div className="text-gray-700 mb-2">
                      <strong>Next Stage:</strong> {nextStage?.stage || "N/A"}
                    </div>
                    <div className="text-gray-600 mb-4">
                      {formatDate(nextStage?.date || new Date())}
                    </div>
                    <Link
                      to={`/dashboard/cropsde/${crop._id}`}
                      className="text-green-600 hover:underline"
                    >
                      Details
                    </Link>
                  </div>
                );
              })
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllCrops;
